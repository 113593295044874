import React from 'react'

function Careers() {
  return (

    <section id="Careers" className="faq">
    <div className="container" data-aos="fade-up">
    <div className="section-title">
        <h2>Careers</h2>
        <p>Careers at MNM Solutions</p>
    </div>
    <div>
       
       <div class="row">
           <div class="col-md-6">
               <img src="assets/img/Careers.jpg" class="img-fluid" alt="Image" />
           </div>
           <div class="col-md-6 d-flex align-items-center">
                <div class="">
                    <div class="card-body ">
                        <p>
                        We are seeking individuals who aspire to start their own business and become entrepreneurs while paving the way for employment and building successful marketing careers. Our main focus areas include:
                        <br></br>
                        <br></br>
                       <strong> Our main focus is on:</strong> <br></br>
                        Sales & Marketing<br></br>
                        Training & development <br></br>
                        Customer relation management<br></br>
                        Entrepreneur <br></br>
                        Budgeting  <br></br>     
                        Time management <br></br>
                        </p>
                     <strong> Mail To <a href="mailto:hr@mnmsolutions.in">hr@mnmsolutions.in</a> for Career opportunities at MNM Solutions </strong>    
                    </div>
                </div>
            </div>

       </div>
 
</div> 
    </div>
  
   </section>
  )
}

export default Careers