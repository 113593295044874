import React, { useState, useEffect } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ContactForm() {
  const [state, handleSubmit] = useForm("xblrlrkw");
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  useEffect(() => {
    if (state.succeeded) {
      toast.success("Your message has been sent. Thank you!");
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: ''
      });
    }
  }, [state.succeeded]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  return (
    <form onSubmit={handleSubmit} className="php-email-form">
      <div className="row">
        <div className="col-md-6 form-group">
          <input
            type="text"
            name="name"
            className="form-control"
            id="name"
            placeholder="Your Name"
            required
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-6 form-group mt-3 mt-md-0">
          <input
            type="email"
            className="form-control"
            name="email"
            id="email"
            placeholder="Your Email"
            required
            value={formData.email}
            onChange={handleChange}
          />
        </div>
      </div>
      <ValidationError
        prefix="Email"
        field="email"
        errors={state.errors}
      />
      <div className="form-group mt-3">
        <input
          type="text"
          className="form-control"
          name="subject"
          id="subject"
          placeholder="Subject"
          required
          value={formData.subject}
          onChange={handleChange}
        />
      </div>
      <div className="form-group mt-3">
        <textarea
          className="form-control"
          name="message"
          rows={5}
          placeholder="Message"
          required
          value={formData.message}
          onChange={handleChange}
        />
      </div>
      <ValidationError
        prefix="Message"
        field="message"
        errors={state.errors}
      />
      <div className="text-center mt-3">
        <button type="submit" disabled={state.submitting}>Submit</button>
      </div>
    </form>
  );
}

function Contact() {
  return (
    <div>
      <section id="contact" className="contact section-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Contact</h2>
            <p>Contact Us</p>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-md-12">
                  <div className="info-box">
                    <i className="bx bx-map" />
                    <h3>Our Address</h3>
                    <p>1st Floor Thumbali Building<br />Opp Gayatri School Near Little Angle School MVP Sector 3 Vizag 530017</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info-box mt-4">
                    <i className="bx bx-envelope" />
                    <h3>Email Us</h3>
                    <p>support@mnmsolutions.in<br />hr@mnmsolutions.in</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info-box mt-4">
                    <i className="bx bx-phone-call" />
                    <h3>Call Us</h3>
                    <p>9000431447<br /><br /></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </div>
  );
}

export default Contact;
