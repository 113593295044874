import About from "./components/About";
import AwardsList from "./components/AwardsList";
import CTA from "./components/CTA";
import Careers from "./components/Careers";
import Contact from "./components/Contact";
import Counters from "./components/Counters";
import Faq from "./components/Faq";
import Fotter from "./components/Fotter";
import Hero from "./components/Hero";
import Nav from "./components/Nav";
import PartnersSection from "./components/PartnersSection";
import Portfolio from "./components/Portfolio";
import Services from "./components/Services";
import Team from "./components/Team";
import Testimonals from "./components/Testimonals";
import WhyUs from "./components/WhyUs";


function App() {
  return (
  <>
  <Nav />
  <Hero />
  <About />
  <CTA />
  
  {/* <WhyUs /> */}
  <Services />
  <PartnersSection />
  <Team />
  <Careers />
  <AwardsList />
  <Portfolio />
  <Testimonals />
  <Contact />
  <Fotter />
  </>

  );
}

export default App;
