import React from 'react';

function About() {
  return (
    <section className="about-section" id="about">
      <div className="container">
        <div className="row clearfix">
          {/* Image Column */}
          <div className="image-column col-md-6 col-sm-12 col-xs-12 order-1 order-md-2">
            <div
              className="inner-column"
              data-wow-delay="0ms"
              data-wow-duration="1500ms"
            >
              <div className="image">
                <img src="assets/img/about.jpg" alt="" />
                {/* <div className="overlay-box">
                  <div className="year-box">
                    <span className="number">5</span>Years <br /> Experience <br />{" "}
                    Working
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          {/* Content Column */}
          <div className="content-column col-md-6 col-sm-12 col-xs-12 order-2 order-md-1">
            <div className="inner-column">
              <div className="sec-title">
                <div className="title">About Us</div>
                <h2>
                  We Are The Leader In <br /> Marketing Agency
                </h2>
              </div>
              <div className="text" >
              MNM Solutions is a premier marketing agency specializing in the insurance sector. We collaborate with diverse brands to promote a wide array of products across various regions. Our expertise extends to industries such as financial services, telecommunications, and insurance, providing a comprehensive range of products and services.
              <br></br>
              <br></br>
              We work closely with our clients to develop effective, sales-driven campaigns, consistently delivering hundreds of new customers daily. Our proven, foolproof sales methodologies empower our clients to expand and enhance their businesses.
              <br></br>
              <br></br>
              As we grow into new cities, we continually broaden the market through innovative business tactics and marketing strategies. We are committed to developing and honing our clients' skills, supporting them in building ambitious and successful careers.
              </div>
              
              <div className="email">
                Email:{" "}
                <span className="theme_color">support@mnmsolutions.in</span>
              </div>
              <a href="mailto:support@mnmsolutions.in" className="theme-btn btn-style-three">
              support@mnmsolutions.in
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
