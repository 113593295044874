import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

function Portfolio() {
  const [showModal, setShowModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const portfolioItems = [
    { category: 'app', image: 'assets/img/portfolio/1.jpg' },
    { category: 'web', image: 'assets/img/portfolio/2.jpg' },
    { category: 'app', image: 'assets/img/portfolio/3.jpg' },
    { category: 'card', image: 'assets/img/portfolio/4.jpg' },
    { category: 'web', image: 'assets/img/portfolio/5.jpg' },
    { category: 'app', image: 'assets/img/portfolio/6.jpg' },
    // { category: 'app', image: 'assets/img/portfolio/g3.jpg' },
    // { category: 'card', image: 'assets/img/portfolio/g4.jpg' },
    // { category: 'app', image: 'assets/img/portfolio/g1.jpg' },
    // { category: 'web', image: 'assets/img/portfolio/g5.jpg' },


  ];

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const goToPrevious = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };

  const goToNext = () => {
    if (currentImageIndex < portfolioItems.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };

  return (
    <section id="portfolio" className="portfolio  section-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Gallery</h2>
          <p>Check our Gallery</p>
        </div>

        <div className="row portfolio-container" data-aos="fade-up" data-aos-delay={200}>
          {portfolioItems.map((item, index) => (
            <div key={index} className={`col-lg-4 col-md-6 portfolio-item filter-${item.category}`}>
              <img
                src={item.image}
                className="img-fluid"
                alt=""
                onClick={() => openModal(index)}
                style={{ cursor: 'pointer' }}
              />
            </div>
          ))}
        </div>

        <Modal show={showModal} onHide={closeModal} centered size="xl" >
          <Modal.Header closeButton>
            <Modal.Title>Gallery Image</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center">
              <img
                src={portfolioItems[currentImageIndex].image}
                className="img-fluid"
                alt=""
                style={{ maxHeight: '70vh', backgroundColor: 'transparent' }} // Adjust style to maintain consistency
              />
            </div>
          </Modal.Body>
          <Modal.Footer className='d-flex justify-content-between'>
            <Button variant="danger" onClick={goToPrevious} disabled={currentImageIndex === 0}>
              Previous
            </Button>
            <Button variant="success" onClick={goToNext} disabled={currentImageIndex === portfolioItems.length - 1}>
              Next
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </section>
  );
}

export default Portfolio;
