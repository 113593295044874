import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { FaChevronRight } from 'react-icons/fa';

const awards = [
  {
    company: "ICICI LOMBARD",
    awards: [
      "HIGHEST SOUTH ZONE PREMIUM COLLECTION IN PERSONAL ACCIDENTS POLICY (2022)",
      "HIGHEST PREMIUM COLLECTION IN ALL VERTICALS OF ICICI (2023)",
      "ICICI LOMBARD BEST SALES AWARD IN SOUTH ZONE (2024)"
    ]
  },
  {
    company: "FUTURE GENERALI",
    awards: [
      "1ST QUALIFIED COMPANY FOR FG PRIDE CONTEST FROM INDIA (2024)",
      "BEST SALES AWARD IN SOUTH ZONE (2024)",
      "HIGHEST PREMIUM COLLECTION IN ALL VERTICALS OF FUTURE GENERALI (2023)"
    ]
  }
];

const AwardsList = () => {
  return (
    <div className="container my-4" id='Awards'>
        <div className="section-title">
          <h2>MNM Solution</h2>
          <p>Awards</p>
        </div>
      <ul className="list-group">
        {awards.map((company, index) => (
          <li key={index} className="list-group-item border-0 p-0">
            <h3 className="mb-3 text-center" style={{ fontWeight: "bold" }}>{company.company}</h3>
            {company.awards.map((award, awardIndex) => (
              <div key={awardIndex} className="mb-2 p-3" style={{ backgroundColor: '#FFC107', borderRadius: '5px' }}>
                <div className="row align-items-center">
                  <div className="col-2 col-md-1 bg-danger text-white d-flex align-items-center justify-content-center " style={{ borderRadius: '50%', width: '30px', height: '30px',marginLeft:'10px' }}>
                    <FaChevronRight />
                  </div>
                  <div className="col-10 col-md-11 text-dark font-weight-bold">
                    <p className="mb-0" style={{ fontWeight: "bold" }}>{award}</p>
                  </div>
                </div>
              </div>
            ))}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AwardsList;
