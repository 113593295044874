import React, { useEffect } from 'react';


function Hero() {
  useEffect(() => {
    // Initialize Bootstrap carousel manually if needed
    const heroCarousel = document.querySelector('#heroCarousel');
    if (heroCarousel) {
      const carousel = new window.bootstrap.Carousel(heroCarousel, {
        interval: 5000,
        ride: 'carousel'
      });
    }
  }, []);

  return (
    <section id="hero">
      <div
        id="heroCarousel"
        data-bs-interval={5000}
        className="carousel slide carousel-fade"
        data-bs-ride="carousel"
      >
        <ol className="carousel-indicators" id="hero-carousel-indicators">
          <li data-bs-target="#heroCarousel" data-bs-slide-to="0" className="active"></li>
          <li data-bs-target="#heroCarousel" data-bs-slide-to="1"></li>
          <li data-bs-target="#heroCarousel" data-bs-slide-to="2"></li>
        </ol>
        <div className="carousel-inner" role="listbox">
          {/* Slide 1 */}
          <div
            className="carousel-item active"
            style={{ backgroundImage: "url(assets/img/slide/slide-1.jpg)" }}
          >
            <div className="carousel-container">
              <div className="container">
                <h2 className="animate__animated animate__fadeInDown">
                  Welcome to <span>MNM Solutions</span>
                </h2>
                <p className="animate__animated animate__fadeInUp">
                Leading Marketing Agency In Insurance Sector
                </p>
                <a
                  href="#about"
                  className="btn-get-started animate__animated animate__fadeInUp scrollto"
                >
                  Read More
                </a>
              </div>
            </div>
          </div>
          {/* Slide 2 */}
          <div
            className="carousel-item"
            style={{ backgroundImage: "url(assets/img/slide/slide-2.jpg)" }}
          >
            <div className="carousel-container">
              <div className="container">
                <h2 className="animate__animated animate__fadeInDown">
                 Towards a Bright Future
                </h2>
                <p className="animate__animated animate__fadeInUp">
                Health Insurance Is a Lifeline, Offering Peace Of Mind And Financial Security
                </p>
                <a
                  href="#about"
                  className="btn-get-started animate__animated animate__fadeInUp scrollto"
                >
                  Read More
                </a>
              </div>
            </div>
          </div>
          {/* Slide 3 */}
          <div
            className="carousel-item"
            style={{ backgroundImage: "url(assets/img/slide/slide-3.jpg)" }}
          >
            <div className="carousel-container">
              <div className="container">
                <h2 className="animate__animated animate__fadeInDown">
                  Get on The Right Way
                </h2>
                <p className="animate__animated animate__fadeInUp">
                Vehical Insurance Safeguards Your Journey, Keeping You & Your Investment Safe.  
                </p>
                <a
                  href="#about"
                  className="btn-get-started animate__animated animate__fadeInUp scrollto"
                >
                  Read More
                </a>
              </div>
            </div>
          </div>
        </div>
        <a
          className="carousel-control-prev"
          href="#heroCarousel"
          role="button"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon bi bi-chevron-left"
            aria-hidden="true"
          />
        </a>
        <a
          className="carousel-control-next"
          href="#heroCarousel"
          role="button"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon bi bi-chevron-right"
            aria-hidden="true"
          />
        </a>
      </div>
    </section>
  );
}

export default Hero;
