import React from 'react'

function Services() {
  return (
    <section id="services" className="services">
        <div className="container" data-aos="fade-up">
        <div className="section-title">
            <h2>Services</h2>
            <p>Check our Services</p>
        </div>
        <div className="row">
            <div
            className="col-lg-6 col-md-6 d-flex align-items-stretch"
            data-aos="zoom-in"
            data-aos-delay={100}
            >
            <div className="icon-box">
                <div className="icon">
                <img src='assets/img/services/1.png' alt='' width="150%"  style={{borderRadius:'3px'}}/>
                </div>
                <h4>
                <a href="" style={{ fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',}}>Life & Health Insurance</a>
                </h4>
                <p>
                Life & Health Insurance is crucial for safeguarding against health-related expenses. It provides coverage for medical treatments, hospital stays, surgeries, and other healthcare costs. This type of insurance ensures financial stability during health crises, reducing the burden of unexpected medical bills and offering peace of mind to policyholders.
                </p>
            </div>
            </div>
            <div
            className="col-lg-6 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
            data-aos="zoom-in"
            data-aos-delay={200}
            >
            <div className="icon-box">
                <div className="icon">
                <img src='assets/img/services/tele-calling.jpg' alt='' width="150%"  style={{borderRadius:'3px'}}/>
                </div>
                <h4>
                <a href="" style={{ fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',}}>Tele-Calling</a>
                </h4>
                <p>
                Tele-calling is an effective service for reaching potential clients and providing information about insurance policies. This approach allows for direct communication, helping to explain the benefits and features of Life & Health Insurance, answer questions, and assist clients in choosing the right policy for their needs. Well-trained tele-callers can significantly enhance customer engagement and conversion rates.
                </p>
            </div>
            </div>
            <div
            className="col-lg-6 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0"
            data-aos="zoom-in"
            data-aos-delay={300}
            >
            <div className="icon-box">
                <div className="icon">
               <img src='assets/img/services/training.jpg' alt='' width="150%"  style={{borderRadius:'3px'}}/>
                </div>
                <h4>
                <a href="" style={{ fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',}}> Training & Development </a>
                </h4>
                <p>
                Training & Development are vital components in the insurance industry. They ensure that tele-callers, agents, and service representatives are well-versed in the latest insurance products, regulations, and sales techniques. Continuous training programs help improve communication skills, product knowledge, and customer service, leading to better client interactions and higher satisfaction rates.
                </p>
            </div>
            </div>
            <div
            className="col-lg-6 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0"
            data-aos="zoom-in"
            data-aos-delay={300}
            >
            <div className="icon-box">
                <div className="icon">
               <img src='assets/img/services/service.jpg' alt='' width="150%"  style={{borderRadius:'3px'}}/>
                </div>
                <h4>
                <a href="" style={{ fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',}}>Service Management</a>
                </h4>
                <p>
                Service Management in the insurance sector focuses on delivering high-quality customer service and efficiently managing client relationships. This includes handling policy inquiries, processing claims, and providing ongoing support to policyholders. Effective service management ensures that clients receive timely assistance and their needs are met promptly, fostering trust and loyalty in the insurance company.
                </p>
            </div>
            </div>
          
         
            
        </div>
        </div>
    </section>
  )
}

export default Services