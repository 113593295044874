import React, { useEffect, useRef } from 'react';
import 'swiper/swiper-bundle.css';
import Swiper from 'swiper';

function Testimonials() {
  const swiperRef = useRef(null);

  useEffect(() => {
    const swiper = new Swiper(swiperRef.current, {
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
    });

    return () => {
      swiper.destroy();
    };
  }, []);

  return (
    <section id="testimonials" className="testimonials">
      <div className="container" >
        <div className="section-title">
          <h2>Testimonials</h2>
          <p>What our clients say</p>
        </div>

        <div ref={swiperRef} className="swiper-container testimonials-slider">
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <div className="testimonial-wrap">
                <div className="testimonial-item">
                  <img
                    src="assets/img/testimonials/testimonials-1.jpg"
                    className="testimonial-img"
                    alt=""
                  />
                  <h3>Saul Goodman</h3>
                  <p style={{ textAlign: 'justify' }}>
                    <i className="bx bxs-quote-alt-left quote-icon-left" />
                    "I've been with my insurance company for over five years, and their service management is outstanding. Whenever I have a policy inquiry or need to process a claim, their representatives handle everything promptly and professionally. The ongoing support I receive has built my trust and loyalty. It's comforting to know that I can always rely on them."
                    <i className="bx bxs-quote-alt-right quote-icon-right" />
                  </p>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="testimonial-wrap">
                <div className="testimonial-item">
                  <img
                    src="assets/img/testimonials/testimonials-2.jpg"
                    className="testimonial-img"
                    alt=""
                  />
                  <h3>Sara Wilsson</h3>
                  <p style={{ textAlign: 'justify' }}>
                    <i className="bx bxs-quote-alt-left quote-icon-left" />
                    "The training and development programs at my insurance provider are exceptional. As a service representative, I've received continuous training that keeps me updated on the latest insurance products and regulations. This has greatly improved my communication skills and product knowledge, enabling me to provide better service to our clients. It's a key factor in our high customer satisfaction rates."
                    <i className="bx bxs-quote-alt-right quote-icon-right" />
                  </p>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="testimonial-wrap">
                <div className="testimonial-item">
                  <img
                    src="assets/img/testimonials/testimonials-5.jpg"
                    className="testimonial-img"
                    alt=""
                  />
                  <h3>John Larson</h3>
                  <p style={{ textAlign: 'justify' }}>
                    <i className="bx bxs-quote-alt-left quote-icon-left" />
                    "I was initially hesitant about buying insurance over the phone, but the tele-caller from my insurance company was incredibly knowledgeable and helpful. They clearly explained the benefits and features of various Life & Health Insurance policies, answered all my questions, and guided me in choosing the right policy for my needs. This direct communication made the process smooth and stress-free."
                    <i className="bx bxs-quote-alt-right quote-icon-right" />
                  </p>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="testimonial-wrap">
                <div className="testimonial-item">
                  <img
                    src="assets/img/testimonials/testimonials-4.jpg"
                    className="testimonial-img"
                    alt=""
                  />
                  <h3>Jane Doe</h3>
                  <p style={{ textAlign: 'justify' }}>
                    <i className="bx bxs-quote-alt-left quote-icon-left" />
                    "The customer support at my insurance company is top-notch. They are always available to assist with any queries or concerns I have regarding my policies. Their professionalism and dedication to customer satisfaction are truly commendable."
                    <i className="bx bxs-quote-alt-right quote-icon-right" />
                  </p>
                </div>
              </div>
            </div>
          </div>
    
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
