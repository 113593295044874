import React from 'react';

function CTA() {
  return (
    <section id="cta" className="cta" style={{
      backgroundColor: 'rgba(0, 0, 0, 0.32)',
      backgroundImage: 'url(assets/img/bg.png)',
      backgroundPosition: 'center',
      backgroundRepeat: 'repeat',
      backgroundSize: '20% auto',
      }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 text-start">
          <h3 className="mb-4" style={{
                color: '#e24f3b',
                fontSize: '45px',
                fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
              }}>
                <strong>Plan for your Financial success!</strong>
              </h3>

            <p className="lead text-dark fw-bold">
              Discover a guided, personalized approach to managing your wealth with MNM Solutions
            </p>
          </div>
          <div className="col-md-4 text-center">
            <a className="btn btn-primary btn-lg" href="mailto:support@mnmsolutions.in" style={{ backgroundColor: '#e24f3b', borderColor: '#e24f3b' }} target="_self">
              Know More?
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CTA;
