import React from 'react'

function Team() {
  return (
    <section id="team" className="team section-bg">
            <div className="container" data-aos="fade-up">
            <div className="section-title">
                <h2>Team</h2>
                <p>Check our Team</p>
            </div>
            <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="member" data-aos="zoom-in" data-aos-delay={100}>
                    <img
                    src="assets/img/1.jpg"
                    className="img-fluid"
                    alt=""
                    />
                    <div className="member-info">
                    <div className="member-info-content">
                        <h4>VENKATRAO  CHOUDURI</h4>
                        <span>FOUNDER</span>
                    </div>
                    <div className="social">
                        <a href="">
                        <i className="bi bi-twitter" />
                        </a>
                        <a href="">
                        <i className="bi bi-facebook" />
                        </a>
                        <a href="">
                        <i className="bi bi-instagram" />
                        </a>
                        <a href="">
                        <i className="bi bi-linkedin" />
                        </a>
                    </div>
                    </div>
                </div>
                </div>
                <div className="col-xl-9 col-lg-4 col-md-6" data-wow-delay="0.1s">
                <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: '400px' }}>
                    <h4 style={{color:'#e14c3b'}}><strong>Venkatrao  Chouduri</strong></h4>
                    <strong style={{color:'#8b8b8b'}}>Founder</strong>
                    <h6 className='mt-3 px-1' style={{textAlign:'justify'}}>Mr. Venkat Rao Chouduri is the founder of MNM Solutions, a forward-thinking and dynamic provider known for its customer-centric approach and innovative insurance solutions. With a background in finance and a passion for helping others to secure their futures. Under the supervision and vision of Venkat's leadership, the company is growing rapidly, while focusing on tailored policies that meet the diverse needs of clients across various sectors. Venkat's vision and commitment to excellence drive MNM Solutions mission to make insurance accessible and reliable for all.</h6>
                </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6" data-wow-delay="0.2s">
                <div className="member" data-aos="zoom-in" data-aos-delay={300}>
                    <img
                    src="assets/img/MANAGING-DIRECTOR.jpg"
                    className="img-fluid"
                    alt=""
                    />
                    <div className="member-info">
                    <div className="member-info-content">
                        <h4>KARANAM  MADHURI </h4>
                        <span>MANAGING DIRECTOR</span>
                    </div>
                    <div className="social">
                        <a href="">
                        <i className="bi bi-twitter" />
                        </a>
                        <a href="">
                        <i className="bi bi-facebook" />
                        </a>
                        <a href="">
                        <i className="bi bi-instagram" />
                        </a>
                        <a href="">
                        <i className="bi bi-linkedin" />
                        </a>
                    </div>
                    </div>
                    
                </div>
                </div>
                <div className="col-xl-9 col-lg-4 col-md-6" data-wow-delay="0.1s">
                <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: '330px' }}>
                <h4 style={{color:'#e14c3b'}}><strong>Karanam  Madhuri </strong></h4>
                <strong style={{color:'#8b8b8b'}}>Managing Director</strong>
                <h6 className='mt-3 px-1' style={{textAlign:'justify'}}>Madhuri Karanam is the Managing Director of MNM Solutions, a trusted provider of tailored insurance solutions. With extensive experience in strategic techniques and client management, Madhuri has played a pivotal role in MNM Solutions growth and success. Her commitment to innovation and client satisfaction drives the company's mission to deliver reliable and personalized insurance services to each and every business persons and individuals</h6>
               </div>
               </div>
               <div className="col-xl-3 col-lg-4 col-md-6" data-wow-delay="0.1s">
                <div className="member" data-aos="zoom-in" data-aos-delay={200}>
                    <img
                    src="assets/img/hr.jpg"
                    className="img-fluid"
                    alt=""
                    />
                    <div className="member-info">
                    <div className="member-info-content">
                        <h4>CHIGITI LATHA</h4>
                        <span>HR MANAGER</span>
                    </div>
                    <div className="social">
                        <a href="">
                        <i className="bi bi-twitter" />
                        </a>
                        <a href="">
                        <i className="bi bi-facebook" />
                        </a>
                        <a href="">
                        <i className="bi bi-instagram" />
                        </a>
                        <a href="">
                        <i className="bi bi-linkedin" />
                        </a>
                    </div>
                    </div>
                </div>
                </div>
                <div className="col-xl-9 col-lg-4 col-md-6" data-wow-delay="0.1s">
                <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: '200px' }}>
                    <h4 style={{color:'#e14c3b'}}><strong>Chigiti Latha</strong></h4>
                    <strong style={{color:'#8b8b8b'}}>HR Manager</strong>
                    <h6 className='mt-3 px-2' style={{textAlign:'justify'}}>Believe in yourself and within the resources you have, it’s the process that matters the most, not the time that takes you to achieve your dream.</h6>
                </div>
                </div>
            </div>
            </div>
        </section>
  )
}

export default Team