import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faClock, faGlobe, faPhone, faEnvelope, faChevronRight, faX } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faLinkedin, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import { FaXTwitter } from 'react-icons/fa6';

function Nav() {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const handleMobileMenuToggle = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };

    const handleDropdownToggle = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    return (
        <header id="header" className="-top">
            <div className="container d-flex align-items-center justify-content-between">
                <h1 className="logo">
                    <img src='assets/img/logo-w.jpg' alt='logo' width="100%" />
                </h1>

                <nav id="navbar" className={`navbar order-last order-lg-0 ${isMobileMenuOpen ? 'navbar-mobile' : ''}`}>
                    <ul>
                        <li>
                            <a className="nav-link scrollto" href="#hero">Home</a>
                        </li>
                        <li>
                            <a className="nav-link scrollto" href="#about">About</a>
                        </li>
                        <li>
                            <a className="nav-link scrollto" href="#services">Services</a>
                        </li>
                        <li>
                            <a className="nav-link scrollto" href="#team">Team</a>
                        </li>
                        <li>
                            <a className="nav-link scrollto" href="#Careers">Careers</a>
                        </li>
                        <li>
                            <a className="nav-link scrollto" href="#contact">Contact</a>
                        </li>
                        <li>
                            <a className="getstarted scrollto" href="mailto:support@mnmsolutions.in">support@mnmsolutions.in</a>
                        </li>
                        <li className='d-flex m-0'>
                         
                        </li>
                    </ul>
                    <i className={`bi ${isMobileMenuOpen ? 'bi-x white-icon' : 'bi-list text-danger'} mobile-nav-toggle`} onClick={handleMobileMenuToggle} />
                </nav>
                <div className='d-none d-md-flex  justify-content-between'>
                <a href='https://www.instagram.com/mnm_solutions?igsh=MTBtcW9rZGhwNzZvcg==' target='_blank' className="bg-danger text-white d-flex align-items-center justify-content-center mx-2" style={{ borderRadius: '50%', width: '30px', height: '30px' }}>
                                    <FontAwesomeIcon icon={faInstagram} className="text-white my=5" size="lg" />
                            </a>
                 <a href='https://www.linkedin.com/company/mnm-solutions/' target='_blank' className=" text-white d-flex align-items-center justify-content-center  mx-2" style={{ background:'#0a66c2' ,borderRadius: '50%', width: '30px', height: '30px' , marginLeft:'5px'}}>
                        <FontAwesomeIcon icon={faLinkedin} className="text-white" size="lg" />
                </a>
                <a href='https://www.youtube.com/channel/UCdpD6L12Rl2rOA2MwAOdE0A' target='_blank' className=" text-white d-flex align-items-center justify-content-center  mx-2" style={{ background:'red' ,borderRadius: '50%', width: '30px', height: '30px' , marginLeft:'5px'}}>
                        <FontAwesomeIcon icon={faYoutube} className="text-white" size="lg" />
                </a>
                </div>
                         

            
            </div>

            <div className="d-md-none d-flex justify-content-center py-1">
                <a href='https://www.instagram.com/mnm_solutions?igsh=MTBtcW9rZGhwNzZvcg==' target='_blank' className="social-media">
                    <div className="bg-danger text-white d-flex align-items-center justify-content-center" style={{ borderRadius: '50%', width: '30px', height: '30px' }}>
                        <FontAwesomeIcon icon={faInstagram} className="text-white" size="lg" />
                    </div>
                </a>
                <a href='https://www.linkedin.com/company/mnm-solutions/' target='_blank' className="social-media">
                    <div className=" text-white d-flex align-items-center justify-content-center" style={{ background:'#0a66c2' ,borderRadius: '50%', width: '30px', height: '30px' , marginLeft:'5px'}}>
                        <FontAwesomeIcon icon={faLinkedin} className="text-white" size="lg" />
                    </div>
                </a>
                <a href='https://www.youtube.com/channel/UCdpD6L12Rl2rOA2MwAOdE0A' target='_blank' className="social-media">
                    <div className=" text-white d-flex align-items-center justify-content-center" style={{ background:'red' ,borderRadius: '50%', width: '30px', height: '30px' , marginLeft:'5px'}}>
                        <FontAwesomeIcon icon={faYoutube} className="text-white" size="lg" />
                    </div>
                </a>
            </div>
        </header>
    );
}

export default Nav;
